var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c("h2", { staticClass: "pl-1" }, [
        _vm._v(_vm._s(_vm.$t("views.reports.title")))
      ]),
      _c(
        "v-alert",
        { staticClass: "my-4", attrs: { type: "info", dense: "" } },
        [_vm._v(_vm._s(_vm.$t("views.reports.layout_info")))]
      ),
      _c(
        "v-list",
        [
          _vm._l(_vm.availableReports, function(report) {
            return [
              report.subheader
                ? _c(
                    "v-subheader",
                    { key: report.title, staticClass: "grey lighten-3" },
                    [_vm._v(_vm._s(report.subheader))]
                  )
                : _c(
                    "v-list-item",
                    {
                      key: report.title,
                      attrs: { to: report.to, disabled: report.disabled }
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass: "font-weight-medium",
                              class: report.disabled ? "grey--text" : ""
                            },
                            [_vm._v(" " + _vm._s(report.title) + " ")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { class: report.disabled ? "grey--text" : "" },
                            [_vm._v(" " + _vm._s(report.subtitle) + " ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: report.disabled
                                  ? "grey"
                                  : "blue lighten-2"
                              }
                            },
                            [_vm._v("fas fa-caret-right")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }