<template>
    <v-container class="pa-0">
        <h2 class="pl-1">{{ $t('views.reports.title') }}</h2>

        <v-alert type="info" dense class="my-4">{{ $t('views.reports.layout_info') }}</v-alert>
        <v-list>
            <template v-for="report in availableReports">
                <v-subheader class="grey lighten-3" v-if="report.subheader" :key="report.title">{{ report.subheader }}</v-subheader>
                <v-list-item v-else :to="report.to" :key="report.title" :disabled="report.disabled">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium" :class="report.disabled ? 'grey--text' : '' ">
                            {{ report.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle :class="report.disabled ? 'grey--text' : '' ">
                            {{ report.subtitle }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                        <v-icon :color="report.disabled ? 'grey' : 'blue lighten-2' ">fas fa-caret-right</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </template>
        </v-list>
    </v-container>
</template>
<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    mixins: [ HasErrorHandlerMixin ],
    computed: {
        availableReports() {
            let weekAgo = this.$moment().subtract(1, 'week').format(this.$constants.getConstant('DATETIME_TZ_FORMAT'))
            let now     = this.$moment().format(this.$constants.getConstant('DATETIME_TZ_FORMAT'))
            let fromTo = {
                from: weekAgo,
                to  : now,
            }

            return [
                { subheader: this.$t('views.reports.list.customer.subheader') },
                {
                    title: this.$t('views.reports.list.customer.created_at.title'),
                    subtitle: this.$t('views.reports.list.customer.created_at.subtitle'),
                    to: {
                        name: 'reports.customer.created_at',
                        query: {
                            ...fromTo,
                        },
                    },
                },
                { subheader: this.$t('views.reports.list.voucher.subheader') },
                {
                    title: this.$t('views.reports.list.voucher.withdrawn_at.title'),
                    subtitle: this.$t('views.reports.list.voucher.withdrawn_at.subtitle'),
                    to: {
                        name: 'reports.vouchers',
                        query: {
                            filter_by: 'withdrawn_at',
                            ...fromTo,
                        },
                    },
                },
                {
                    title: this.$t('views.reports.list.voucher.created_at.title'),
                    subtitle: this.$t('views.reports.list.voucher.created_at.subtitle'),
                    to: {
                        name: 'reports.vouchers',
                        query: {
                            filter_by: 'created_at',
                            ...fromTo,
                        },
                    },
                },
                { subheader: this.$t('views.reports.list.points.subheader'), disabled: true },
                {
                    title: this.$t('views.reports.list.points.generator.title'),
                    subtitle: this.$t('views.reports.list.points.generator.subtitle'),
                    to: {
                        name: 'reports.generators',
                        query: {
                            ...fromTo,
                        },
                    },
                },
                {
                    title: this.$t('views.reports.list.points.consumption.title'),
                    subtitle: this.$t('views.reports.list.points.consumption.subtitle'),
                    to: {
                        name: 'reports.consumptions',
                        query: {
                            ...fromTo,
                        },
                    },
                },
                {
                    title: this.$t('views.reports.list.points.interactions.title'),
                    subtitle: this.$t('views.reports.list.points.interactions.subtitle'),
                    to: {
                        name: 'reports.interactions',
                        query: {
                            ...fromTo,
                        },
                    },
                },
                {
                    title: this.$t('views.reports.list.points.frequencies.title'),
                    subtitle: this.$t('views.reports.list.points.frequencies.subtitle'),
                    to: {
                        name: 'reports.frequencies',
                        query: {
                            ...fromTo,
                        },
                    },
                },
                { subheader: this.$t('views.reports.list.custom.subheader'), disabled: true },
            ]
        },
    },
}
</script>